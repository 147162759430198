<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from "vue";

import actions from "~/actions";
import { UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TaskChips from "~/components/visualization/components/TaskChips.vue";
import { THROTTLE_MS } from "~/constants/app";
import { ChevronLeftDoubleIcon } from "~/icons";
import { CommandId, EditorMode, RelationshipKindKind, SubtaskDisplayMode } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { ThrottleManager } from "~/utils/throttleManager";

const props = defineProps<{
  task: Task;
  value: string;
  editorMode: EditorMode;
  hasShownParent: boolean;
  subtasksExpanded: boolean;
}>();

const emit = defineEmits<{
  toggleSubtasks: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const isListMiniTcmMode = computed(() => props.editorMode === EditorMode.LIST_MINI_TCM);
const isListMiniRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI_ROADMAP);
const isListMiniNonRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI || isListMiniTcmMode.value);

const title = ref(props.value);

const titleRef = ref<HTMLSpanElement | null>(null);
const globalTask = computed(() => dataStore.getTaskByDuid(props.task.duid));
const neverUpdated = props.task.createdAt === props.task.updatedAt;

const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);
const isSelected = computed(() => appStore.selectedTaskDuids.has(props.task.duid));
const openTaskDisabled = computed(
  () => isSelected.value && (appStore.taskDetailOpen || appStore.selectedTaskDuids.size > 1)
);

const focus = () => {
  if (!titleRef.value) {
    return;
  }

  const { firstChild } = titleRef.value;
  if (firstChild) {
    const range = document.createRange();
    range.setStart(firstChild, titleRef.value.innerText.length);
    range.collapse(true);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  titleRef.value?.focus();
};

const openTaskInDetail = (subtaskOverride: boolean) => {
  const visualization = appStore.getBaseVisualization();

  if (
    visualization.getSelectedRows().length > 1 ||
    appStore.taskOpenInDetail === props.task ||
    (isListMiniNonRoadmapMode.value && !subtaskOverride)
  ) {
    return;
  }

  const { duid } = props.task;
  if (!visualization.getRow(duid)) {
    actions.visualization.navigateToTask(duid);
    return;
  }

  if (isListMiniNonRoadmapMode.value) {
    visualization.selectAndScrollTo(duid);
    return;
  }

  setTimeout(() => {
    appStore.setTaskDetailOpen(true);
  });
};

const clickReminderOpen = () => {
  appStore.setTasksOpenInReminderModal([props.task]);
};

const jumpToDescription = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  actions.visualization.jumpToDescription(props.task);
};

const jumpToComments = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  actions.visualization.jumpToComments(props.task);
};

const toggleSubtasks = () => {
  if (!notFlat.value) {
    openTaskInDetail(false);
    return;
  }
  emit("toggleSubtasks");
};

let isTabbing = false;

const deleteTaskIfEmpty = () => {
  if (isTabbing) {
    isTabbing = false;
    return false;
  }
  if (!dataStore.getTaskByDuid(props.task.duid)) {
    // task was already deleted by backspace
    return true;
  }
  if (!neverUpdated || titleRef.value?.innerText?.trim()) {
    return false;
  }

  dataStore.deleteTasks([props.task]);
  // TODO scroll back to select (and maybe start editing) the next higher task or the new task input
  return true;
};

const saveManager = new ThrottleManager((value: string) => {
  dataStore.updateTasks(
    [
      {
        duid: props.task.duid,
        title: value,
      },
    ],
    { noUndo: true }
  );
}, THROTTLE_MS);

const onInput = () => {
  if (!titleRef.value) {
    return;
  }
  const newTitle = titleRef.value.innerText.trim();
  saveManager.run(newTitle);

  if (!globalTask.value) {
    return;
  }
  globalTask.value.title = newTitle;
};

const onPaste = (event: ClipboardEvent) => {
  const visualization = appStore.getActiveVisualization();
  const allRows = visualization.getAll();
  const indexOfTask = allRows.findIndex((row: Task) => row.duid === props.task.duid);
  const belowTaskOrder = allRows[indexOfTask + 1]?.order ?? "";

  const parentRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
  const parentTaskDuid = dataStore
    .getRelationships(props.task)
    .find((e) => e.kindDuid === parentRelationshipKindDuid && !e.isForward)?.targetDuid;

  const remainingText = actions.task.createTasksFromPaste(
    event,
    props.task.dartboardDuid,
    props.task.order,
    belowTaskOrder,
    {
      drafterDuid: isListMiniTcmMode.value ? userStore.duid : undefined,
    },
    {
      parentTaskDuid,
    }
  );
  title.value = remainingText ?? "";
  saveManager.run(title.value);

  focus();
};

const onBlur = () => {
  if (deleteTaskIfEmpty()) {
    return;
  }

  saveManager.finish();
};

const onKeydown = (event: KeyboardEvent) => {
  if (titleRef.value === null) {
    return;
  }

  if (event.key === "Escape") {
    event.stopPropagation();
    event.preventDefault();
    titleRef.value.blur();
    return;
  }

  if (event.key === "Enter") {
    titleRef.value.blur();
    if ((titleRef.value?.innerText?.trim() ?? "") !== "") {
      const partialTask: Partial<Task> = {};

      if (isListMiniRoadmapMode.value) {
        partialTask.startAt = props.task.startAt;
        partialTask.dueAt = props.task.dueAt;
      }

      if (appStore.groupBy !== UNGROUPED_PSEUDO_GROUP_BY) {
        Object.assign(
          partialTask,
          getPropertyPartialTask(
            appStore.groupByDefinition.property,
            partialTask,
            getPropertyValueFromTask(appStore.groupByDefinition.property, props.task)
          )
        );
      }

      actions.visualization.createTaskUnderneath(partialTask);
    }
    return;
  }

  if (event.key === "Tab") {
    event.preventDefault();
    isTabbing = true;
    titleRef.value.blur();
    if (!isListMiniNonRoadmapMode.value) {
      const partialTask = { title: titleRef.value?.innerText?.trim() ?? "" };
      if (event.shiftKey) {
        actions.visualization.subtaskOutdent(partialTask);
      } else {
        actions.visualization.subtaskIndent(partialTask);
      }
      setTimeout(() => {
        actions.visualization.startEditingTitle();
      }, 100);
    }
    return;
  }

  if (event.key === "Backspace") {
    deleteTaskIfEmpty();
  }
};

watch(
  () => props.value,
  (newValue) => {
    if (newValue.trim() === (titleRef.value?.innerText?.trim() ?? "")) {
      return;
    }
    title.value = newValue;
  }
);

onUnmounted(() => {
  saveManager.destroy();
});

defineExpose({
  focus,
});
</script>

<template>
  <div class="group/title flex h-full grow items-center gap-1.5 truncate">
    <div
      class="flex truncate rounded border border-transparent"
      :class="!pageStore.isPublicView && !task.inTrash && 'group-hover/title:border-oncolor'">
      <span
        ref="titleRef"
        contenteditable
        :title="title"
        data-testid="title-editor"
        class="-my-px mx-px min-h-[20px] min-w-[40px] cursor-text border-none bg-transparent text-sm font-normal focus-ring-none selection:bg-indigo-300 dark:selection:bg-indigo-700 [&:not(:focus)]:truncate"
        @dblclick="openTaskInDetail(true)"
        @mousedown.stop
        @input="onInput"
        @paste.prevent="onPaste"
        @keydown="onKeydown"
        @blur="onBlur">
        {{ title }}
      </span>
    </div>
    <template v-if="!pageStore.isMobile">
      <div class="pointer-events-auto flex items-center gap-1.5">
        <TaskChips
          :task="task"
          :editor-mode="editorMode"
          :has-shown-parent="hasShownParent"
          :subtasks-expanded="subtasksExpanded"
          @open-task-in-detail="openTaskInDetail(false)"
          @jump-to-description="jumpToDescription"
          @jump-to-comments="jumpToComments"
          @toggle-subtasks="toggleSubtasks"
          @open-reminder="clickReminderOpen" />
      </div>
      <div
        v-if="!isListMiniTcmMode"
        class="dart-open-rightbar flex h-full grow items-center justify-end"
        :class="isListMiniNonRoadmapMode ? 'cursor-default' : 'cursor-pointer'"
        @click="openTaskInDetail(false)"
        @keydown.enter="openTaskInDetail(false)">
        <Tooltip
          :disabled="openTaskDisabled"
          :command-id="CommandId.OPEN_TASK_IN_RIGHTBAR"
          :hide-strokes="isListMiniNonRoadmapMode">
          <div
            class="flex cursor-pointer items-center rounded border border-gray-900/60 text-gray-900/60 hover:bg-gray-900/10 dark:border-white/50 dark:text-white/50 dark:hover:bg-white/10 print:hidden"
            :class="{
              'opacity-0': !isSelected || openTaskDisabled,
              'group-hover/title:opacity-100': !isSelected && !openTaskDisabled,
            }"
            data-testid="open-task-in-detail"
            @click="openTaskInDetail(true)"
            @keydown.enter="openTaskInDetail(true)">
            <ChevronLeftDoubleIcon class="size-[18px]" />
            <div v-if="isSelected && !openTaskDisabled" class="pr-1 text-xs">Open</div>
          </div>
        </Tooltip>
      </div>
    </template>
  </div>
</template>
