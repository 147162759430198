<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { ReportsIcon, SpaceFieldIcon, SprintIcon, UsersInviteIcon } from "~/icons";
import { makeLinkToSpaceSettingsPageRef } from "~/router/common";

import BasicsView from "./subpages/BasicsView.vue";
import ReportsView from "./subpages/ReportsView.vue";
import SharingView from "./subpages/SharingView.vue";
import SprintsView from "./subpages/SprintsView.vue";

const VIEWS = [
  { title: "Basics", component: BasicsView, icon: SpaceFieldIcon, page: "basics" },
  { title: "Sprints", component: SprintsView, icon: SprintIcon, page: "sprints" },
  { title: "Reports", component: ReportsView, icon: ReportsIcon, page: "reports" },
  { title: "Sharing", component: SharingView, icon: UsersInviteIcon, page: "sharing" },
];

const router = useRouter();

const currentSettingsPage = computed(() => router.currentRoute.value.query.page ?? "basics");

const spacesViews = computed(() =>
  VIEWS.map((view) => ({
    ...view,
    active: view.page === currentSettingsPage.value,
    link: makeLinkToSpaceSettingsPageRef(router.currentRoute.value.query.space as string, view.page).value,
  }))
);
</script>

<template>
  <div class="h-full w-40 select-none border-r px-2.5 pb-6 pt-5 bg-std border-lt md:w-56">
    <div class="mb-1.5 pl-2.5 text-xs font-semibold uppercase text-vlt">Space settings</div>
    <RouterLink
      v-for="view in spacesViews"
      :key="view.title"
      :to="view.link"
      data-testid="space-section-link"
      :class="view.active ? 'bg-lt hover:bg-md' : 'hover:bg-lt'"
      class="mt-px flex w-full items-center rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt">
      <component :is="view.icon" class="mr-1.5" :class="[view.active ? 'text-primary-base' : 'text-lt', 'icon-sm']" />
      <span :title="view.title">{{ view.title }}</span>
    </RouterLink>
  </div>
</template>
