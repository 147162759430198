<script setup lang="ts">
import { nextTick, ref } from "vue";

import { dismiss, queue } from "./notification";
import Notification from "./Notification.vue";

// Used for pausing dismissal when hovering over notifications.
const hovering = ref(false);

const onDismiss = (id: string) => {
  hovering.value = false;
  nextTick(() => dismiss(id));
};
</script>

<template>
  <Teleport to="body">
    <TransitionGroup
      name="notifications"
      tag="div"
      class="fixed bottom-0 left-0 z-[49] m-4 flex w-full max-w-xs flex-col gap-3"
      data-testid="notification-list"
      @mouseover="hovering = true"
      @mouseleave="hovering = false"
      @focus="hovering = true"
      @blur="hovering = false">
      <Notification
        v-for="notification in queue"
        :key="notification.id"
        :notification="notification"
        :dismiss="() => onDismiss(notification.id)"
        :hovering="hovering" />
    </TransitionGroup>
  </Teleport>
</template>

<style>
.notifications-enter-active,
.notifications-leave-active {
  transition: all 300ms ease;
}
.notifications-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.notifications-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
