<script setup lang="ts">
import { type ElementData, Handle, Position } from "@vue-flow/core";

defineProps<{
  data: ElementData;
}>();
</script>

<template>
  <Handle id="end" type="target" :position="Position.Left" :style="{ top: '50%' }" />
  <Handle id="end" type="source" :position="Position.Right" :style="{ top: '50%' }" />
</template>
