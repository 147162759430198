<script setup lang="ts">
import { clamp } from "@vueuse/core";

defineProps<{
  value: number;
  noContent?: boolean;
}>();
</script>

<template>
  <div class="size-full overflow-hidden bg-md" :class="noContent ? 'rounded-full' : 'rounded'">
    <div
      class="h-full transition-all"
      :class="noContent ? 'bg-primary-base' : 'bg-primary-base/50'"
      :style="{ width: `${clamp(value, 0, 100)}%` }" />
  </div>
</template>
