<script setup lang="ts">
import { computed, ref } from "vue";

import { getDefaultPropertiesToFieldsMap, getShownPropertyWithConfigList } from "~/common/properties";
import AttachmentInputButton from "~/components/dumb/AttachmentInputButton.vue";
import Button from "~/components/dumb/Button.vue";
import ButtonWithDropdown from "~/components/dumb/ButtonWithDropdown.vue";
import DartboardEditor from "~/components/dumb/DartboardEditor.vue";
import RelationshipEditor from "~/components/dumb/RelationshipEditor.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import AnimatedChip from "~/components/task/properties/AnimatedChip.vue";
import { ChildRelationshipIcon, DescriptionFieldIcon } from "~/icons";
import {
  ButtonStyle,
  CommandId,
  DropdownMenuItemKind,
  EditorMode,
  IconSize,
  PropertyKind,
  RelationshipKindKind,
} from "~/shared/enums";
import type { DropdownMenuSection, Task, TaskField, TaskFieldRecommendation, TaskFieldValue } from "~/shared/types";
import { usePageStore, useTenantStore } from "~/stores";

type IAnimatedChip = InstanceType<typeof AnimatedChip>;

const EXCLUDED_PROPERTY_KINDS = new Set([
  PropertyKind.DEFAULT_STATUS,
  PropertyKind.SELECT,
  PropertyKind.MULTISELECT,
  PropertyKind.STATUS,
  PropertyKind.USER,
  PropertyKind.DATES,
  PropertyKind.NUMBER,
  PropertyKind.CHECKBOX,
  PropertyKind.TEXT,
]);

const DEFAULT_PROPERTIES_TO_FIELDS_MAP = getDefaultPropertiesToFieldsMap();

const props = defineProps<{
  task: Task;
  recommendations: TaskFieldRecommendation[];
  defaultValues: Map<TaskField, TaskFieldValue>;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  update: [field: keyof Task];
  toggleDescription: [];
  addSubtask: [];
  setSubtask: [];
  rejectRecommendation: [field: keyof Task];
  addAttachments: [files: File[]];
}>();

const pageStore = usePageStore();
const tenantStore = useTenantStore();

const isTcmMobileMode = computed(() => props.editorMode === EditorMode.TCM && pageStore.isMobile);

const properties = computed(() =>
  getShownPropertyWithConfigList()
    .filter(
      ([property, config]) =>
        !(config.readOnly || (isTcmMobileMode.value && EXCLUDED_PROPERTY_KINDS.has(property.kind)))
    )
    .map(([property, config]) => {
      const propertiesView = config.editor();
      const field: keyof Task | undefined = DEFAULT_PROPERTIES_TO_FIELDS_MAP.get(property);
      return {
        duid: property.duid,
        title: property.title,
        field,
        defaultValue: field ? props.defaultValues.get(field) : null,
        component: propertiesView.component,
        values: propertiesView.getValues(property, props.task),
      };
    })
);
const attachmentInput = ref<InstanceType<typeof AttachmentInputButton> | null>(null);

const animationChipRefs = new Map<string, IAnimatedChip | null>();
const addAnimationChipRef = (field: string, elem: IAnimatedChip | null) => {
  animationChipRefs.set(field, elem);
};

const recommendedPropertiesMap = computed(
  () => new Map(props.recommendations.map(({ field, value }) => [field, value]))
);

const getEditorMode = (field?: keyof Task) => {
  if (field === "assigneeDuids") {
    return recommendedPropertiesMap.value.has("assignedToAi") || recommendedPropertiesMap.value.has("assigneeDuids")
      ? EditorMode.CHIP_RECOMMENDATION
      : EditorMode.CHIP;
  }

  return field && recommendedPropertiesMap.value.has(field) ? EditorMode.CHIP_RECOMMENDATION : EditorMode.CHIP;
};

const addAttachments = (files: File[]) => emit("addAttachments", files);

const openFilePicker = () => attachmentInput.value?.open();

const animateChipRecommendations = (source: HTMLElement, fields: Set<string>) => {
  animationChipRefs.forEach((v, k) => {
    if (!fields.has(k)) {
      return;
    }
    v?.animate(source);
  });
};

const onUpdate = (field: keyof Task) => {
  emit("update", field);
  if (field === "dueAt" && tenantStore.startDateEnabled) {
    emit("update", "startAt");
  }
};

const onReject = (field: keyof Task) => {
  emit("rejectRecommendation", field);
};

const subtaskDropdownOptions: DropdownMenuSection[] = [
  {
    title: "Subtasks",
    items: [
      {
        title: "Create new subtask",
        kind: DropdownMenuItemKind.BUTTON,
        commandId: CommandId.CREATE_SUBTASKS,
        onClick: () => emit("addSubtask"),
      },
      {
        title: "Set existing subtask",
        kind: DropdownMenuItemKind.BUTTON,
        onClick: () => emit("setSubtask"),
      },
    ],
  },
];

defineExpose({
  animateChipRecommendations,
  openFilePicker,
});
</script>

<template>
  <div data-testid="property-chips" class="flex w-full flex-col px-5">
    <div class="flex flex-wrap items-center gap-2 text-sm text-md">
      <Button
        v-if="isTcmMobileMode"
        text="Description"
        :icon="DescriptionFieldIcon"
        :icon-size="IconSize.S"
        :btn-style="ButtonStyle.CHIP"
        class="py-0.5"
        @click="emit('toggleDescription')" />

      <DartboardEditor
        v-if="!isTcmMobileMode"
        :tasks="[task]"
        :editor-mode="EditorMode.CHIP"
        @update="emit('update', 'dartboardDuid')" />

      <RelationshipEditor
        v-if="!isTcmMobileMode"
        :task="task"
        :relationship-kind-kind="RelationshipKindKind.PARENT_OF"
        :is-forward="false"
        singleselect
        :editor-mode="EditorMode.CHIP" />

      <AnimatedChip
        v-for="property in properties"
        :key="property.duid"
        :ref="(elem) => property.field && addAnimationChipRef(property.field, elem as IAnimatedChip)">
        <component
          :is="property.component"
          v-bind="property.values"
          :editor-mode="getEditorMode(property.field)"
          :default-value="property.defaultValue"
          show-text
          @update="() => property.field && onUpdate(property.field)"
          @reject="() => property.field && onReject(property.field)" />
      </AnimatedChip>

      <!-- TODO Add this back--just need to solve stacked modal issue -->
      <!--  <LinkChip :task="task" /> -->

      <Tooltip :command-id="CommandId.ADD_ATTACHMENT">
        <AttachmentInputButton
          ref="attachmentInput"
          :num-of-attachments="task.attachmentDuids.length"
          :editor-mode="EditorMode.CHIP"
          @input="addAttachments" />
      </Tooltip>

      <ButtonWithDropdown
        v-if="!isTcmMobileMode"
        :dropdown-sections="subtaskDropdownOptions"
        :command-id="CommandId.CREATE_SUBTASKS"
        :btn-style="ButtonStyle.CHIP"
        text="Subtasks"
        :icon="ChildRelationshipIcon"
        :icon-size="IconSize.S"
        @click="emit('addSubtask')" />
    </div>
  </div>
</template>
