<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import OptionComponent from "~/components/options/Option.vue";
import { styleConfig } from "~/constants/style";
import { ChevronRightIcon, DotsHorizontalIcon, SwapIcon, XIcon } from "~/icons";
import { CommandId, DropdownMenuItemKind, Placement } from "~/shared/enums";
import type { MultiselectDropdownMenuItem, Option } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";

// eslint-disable-next-line import/no-self-import
import OptionDropdownItem from "./OptionDropdownItem.vue";
import OptionEditDropdown from "./OptionEditDropdown.vue";

const props = defineProps<{
  option: Option;
  selected: boolean;
  selectedOptionDuids: (string | null)[];
  isNested: boolean;
  showDescendants: boolean;
  isChip?: boolean;
  isFilter?: boolean;
  replaceEnabled?: boolean;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const colorSet = computed(() => getChipColorsHex(props.option.colorHex, pageStore.theme));
const hoverColor = computed(() => (pageStore.theme === "dark" ? colorSet.value.back : styleConfig.colors["white/30"]));

const emit = defineEmits<{
  remove: [];
  swap: [duid: string];
  select: [item: MultiselectDropdownMenuItem];
}>();

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, actions.context.option(props.option), { maxHeightPixels: 566 });
};

const descendants = computed(() => {
  if (!props.showDescendants) {
    return [];
  }
  const property = dataStore.getPropertyByDuid(props.option.propertyDuid);
  return property ? dataStore.getOptionList(property).filter((e) => e.parentDuid === props.option.duid) : [];
});

const descendantSubmenu = computed(() => [
  {
    title: props.option.title,
    items: [props.option]
      .concat(descendants.value)
      .filter((e) => !props.selectedOptionDuids.includes(e.duid))
      .map((e) => ({
        title: e.title,
        kind: DropdownMenuItemKind.COMPONENT,
        component: OptionDropdownItem,
        componentArgs: {
          option: e,
          selected: false,
          selectedOptionDuids: props.selectedOptionDuids,
          isNested: true,
          showDescendants: e.duid !== props.option.duid,
          isFilter: props.isFilter,
          replaceEnabled: props.replaceEnabled,
          onSelect: (item: MultiselectDropdownMenuItem) => emit("select", item),
          onSwap: (duid: string) => emit("swap", duid),
        },
      })),
  },
]);

const onClick = (event: Event) => {
  if (pageStore.isMobile) {
    return;
  }
  event.stopPropagation();
};

const onOptionClick = (e: Event) => {
  e.stopImmediatePropagation();
  emit("select", { value: props.option.duid, label: props.option.title, selected: false, component: OptionComponent });
};
</script>

<template>
  <div
    v-if="selected && isChip"
    class="group/option relative"
    :style="{ '--background': hoverColor }"
    @contextmenu="onContextMenu">
    <OptionComponent :option="option" item-class="min-w-[40px]" show-ancestors>
      <div
        class="cursor-pointer rounded hover:bg-[--background]"
        :style="{ color: colorSet.fore }"
        @click="emit('remove')"
        @keydown.enter="emit('remove')">
        <XIcon class="icon-xs" />
      </div>
    </OptionComponent>
    <div class="absolute inset-y-px right-[19px] opacity-0 bg-std group-hover/option:opacity-100">
      <div
        class="flex size-full items-center py-0.5 pl-1 pr-0.5"
        :style="{ color: colorSet.fore, backgroundColor: colorSet.back }">
        <OptionEditDropdown :option="option">
          <div class="cursor-pointer rounded hover:bg-[--background]">
            <DotsHorizontalIcon class="cursor-pointer rounded icon-xs" />
          </div>
        </OptionEditDropdown>
      </div>
    </div>
  </div>

  <DropdownMenu
    v-else-if="showDescendants && descendants.length > 0"
    :key="option.duid"
    :sections="descendantSubmenu"
    :placement="Placement.RIGHT_TOP"
    :distance="4"
    show-on-hover
    prevent-close-on-select
    class="w-full"
    @keydown.enter.stop
    @click.stop>
    <div
      class="flex w-full max-w-full items-center"
      :class="isNested && 'px-2 py-1'"
      @click="onClick"
      @keydown.enter="onClick">
      <OptionComponent :option="option" is-first-ancestor />
      <div class="flex grow items-center justify-end">
        <ChevronRightIcon class="text-vlt icon-sm" />
      </div>
    </div>
  </DropdownMenu>

  <div
    v-else
    class="flex w-full items-center gap-2"
    :class="isNested && 'px-2 py-1'"
    @contextmenu="onContextMenu"
    @click.stop="onOptionClick"
    @keydown.enter="onOptionClick">
    <div class="flex-1 truncate">
      <OptionComponent v-if="showDescendants" :option="option" />
      <span v-else>{{ `${isFilter ? "Use" : "Add"} ${option.title}` }}</span>
    </div>
    <template v-if="isFilter">
      <Tooltip v-if="replaceEnabled" :command-id="CommandId.SWAP">
        <div
          class="flex items-center justify-center rounded icon-md hover:bg-hvy"
          @click.stop="emit('swap', option.duid)"
          @keydown.enter.stop="emit('swap', option.duid)">
          <SwapIcon class="cursor-pointer text-md icon-xs" />
        </div>
      </Tooltip>
    </template>
    <OptionEditDropdown v-else :option="option">
      <DotsHorizontalIcon class="cursor-pointer rounded text-lt icon-sm hover:bg-hvy focus:outline-none" />
    </OptionEditDropdown>
  </div>
</template>
