<script setup lang="ts">
defineProps<{
  moreAfter?: boolean;
  noTurn?: boolean;
}>();
</script>

<template>
  <div class="relative flex w-6 shrink-0 flex-col items-end self-stretch">
    <div v-if="moreAfter" class="absolute top-0 mr-[0.5px] h-full w-3 border-l border-md" />
    <div v-if="!noTurn" class="absolute top-0 mr-[-3.5px] h-5 w-4 rounded-bl-lg border-b border-l border-md" />
  </div>
</template>
