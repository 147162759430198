<script setup lang="ts">
import { createCSVImporter } from "csv-import-js";
import { computed, ref, watchEffect } from "vue";
import { RouterLink } from "vue-router";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import { notify } from "~/components/notifications";
import { IMPORT_SOURCES } from "~/constants/import";
import { colorsByTheme } from "~/constants/style";
import { ArrowLeftIcon } from "~/icons";
import { ButtonStyle, DialogMode } from "~/shared/enums";
import { usePageStore } from "~/stores";

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const wrapper = ref<HTMLElement | null>(null);

const selectedSource = ref<string | null>(null);
const sourceOptions = computed(() =>
  IMPORT_SOURCES.map((title) => ({
    title,
    selected: title === selectedSource.value,
    onClick: () => {
      selectedSource.value = title;
    },
  }))
);

const hideCancelButton = ref(false);
const goBack = () => {
  selectedSource.value = null;
};

const template = {
  columns: [
    {
      name: "ID",
      key: "id",
      required: true,
      description:
        "The unique ID of the task. If the tasks do not already have IDs, you can just number the tasks 1, 2, 3, etc. in this column",
    },
    {
      name: "Title",
      key: "title",
      required: true,
      description: "The short explanation of the task",
    },
    {
      name: "Dartboard",
      key: "dartboard",
      required: true,
      description: "The list, folder, project, sprint, or team of the task",
    },
    {
      name: "Status",
      key: "status",
      required: true,
      description: "The status of the task. Common statuses are 'To-do', 'Doing', and 'Done'",
    },
    {
      name: "Description",
      key: "description",
      description: "The full description, scope, or requirements of the task, in text or markdown format",
    },
    {
      name: "Parent ID",
      key: "parentId",
      description: "If this task is a subtask, this is the ID of the tasks's parent",
    },
    {
      name: "Assignee emails",
      key: "assigneeEmails",
      description: "A comma-separated list of emails of the assignees",
    },
    {
      name: "Tags",
      key: "tags",
      description: "A comma-separated list of tags or labels for the task",
    },
    {
      name: "Priority",
      key: "priority",
      description: "One of 'Critical', 'High', 'Medium', or 'Low'",
    },
    {
      name: "Start date",
      key: "startAt",
      description: "The date that work on the task started in a standard format like ISO8601",
    },
    {
      name: "Due date",
      key: "dueAt",
      description: "The date that the task is due or work on it ended in a standard format like ISO8601",
    },
    {
      name: "Size",
      key: "size",
      description: "The estimate or story points of the task, one of 'XS', 'S', 'M', 'L', or 'XL'",
    },
  ],
};

watchEffect(() => {
  if (!wrapper.value || !selectedSource.value) {
    return;
  }

  createCSVImporter({
    isModal: false,
    domElement: wrapper.value,
    template,

    onComplete: (data) => {
      if (!selectedSource.value) {
        return;
      }
      backendOld.workspace.import(data, selectedSource.value);
      notify({
        message: "Your data import has started, please check back later to see the new tasks",
        actions: [
          {
            label: "Close import",
            onClick: (dismiss) => dismiss(),
            component: RouterLink,
            componentArgs: {
              to: { name: "home" },
            },
          },
        ],
      });
      hideCancelButton.value = true;
    },

    showDownloadTemplateButton: true,
    // Selects the first row as the header row
    skipHeaderRowSelection: true,
    customStyles: {
      "font-family": "Inter var",
      "border-radius": "4px",
    },
    // TODO Improve text as needed https://github.com/tableflowhq/csv-import/blob/main/src/i18n/es.ts
    customTranslations: {
      en: {
        "- Select one -": "Select one",
      },
    },
  });
});
</script>

<template>
  <div v-if="!selectedSource" class="mx-10 mt-10">
    <h4 class="mb-2 select-none font-semibold text-md">Source</h4>
    <DropdownMenuWithSelection :options="sourceOptions" border>
      <div class="w-full select-none rounded px-3 py-2 text-left shadow-sm focus-ring-std hover:bg-lt">
        {{ selectedSource ?? "Select source" }}
      </div>
    </DropdownMenuWithSelection>
  </div>
  <div v-if="selectedSource" ref="wrapper" class="!m-0 size-full !p-0" />
  <div v-if="selectedSource" class="absolute bottom-[131px] ml-7 sm:bottom-7" :class="hideCancelButton && 'hidden'">
    <ConfirmationDialog
      :mode="DialogMode.DELETE"
      title="Cancel import"
      description="Are you sure you want to go back to source selection? Your import progress will be lost."
      confirm-text="Confirm"
      cancel-text="Cancel"
      :icon="ArrowLeftIcon"
      @confirm="goBack">
      <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" class="!px-3 !py-2" is-contrast />
    </ConfirmationDialog>
  </div>
</template>

<style>
/* https://github.com/tableflowhq/csv-import/blob/main/src/importer/style/themes/light.scss */
.csv-importer.CSVImporter-div {
  --color-background: v-bind("colors.bgStd");
  --color-background-modal: v-bind("colors.bgStd");

  --color-text: v-bind("colors.textLt");
  --color-primary: v-bind("colors.primary");
  --color-primary-hover: v-bind("colors.primaryHover");

  --color-input-background: v-bind("colors.bgLt");
  --color-input-background-soft: v-bind("colors.bgMd");
  --color-input-border: v-bind("colors.borderVlt");
  --color-input-placeholder: v-bind("colors.textMd");
  --color-input-text-disabled: v-bind("colors.textMd");

  --color-border: v-bind("colors.borderMd");

  --color-background-small-button-selected: v-bind("colors.bgLt");
  --color-background-small-button-hover: v-bind("colors.bgMd");
  --color-text-small-button: v-bind("colors.textLt");

  /* Icons */
  --color-icon: v-bind("colors.textVlt");

  height: 100%;
}

.chakra-button[class*="csv-importer-"] {
  @apply !rounded !border-gray-300 !px-3 !py-2 !text-sm !text-gray-700 dark:!border-zinc-600 dark:!text-zinc-300;
}

.chakra-button[class*="csv-importer-"]:hover {
  @apply !bg-gray-200 !text-gray-700 dark:!bg-zinc-700 dark:!text-zinc-300;
}

.chakra-button__icon[class*="csv-importer-"] {
  @apply !me-1.5 !icon-md;
}

.chakra-button[class*="csv-importer-"] {
  @apply !rounded !border !border-solid !border-gray-300 !bg-transparent !px-3 !py-1 !text-sm !text-gray-700 dark:!border-zinc-600 dark:!text-zinc-300;
}

.chakra-button[class*="csv-importer-"]:hover {
  @apply !bg-gray-200 dark:!bg-zinc-700;
}

.chakra-button[class*="csv-importer-"][type="submit"] {
  @apply !border-transparent !bg-primary-base !text-white dark:!text-white;
}

.chakra-button[class*="csv-importer-"][type="submit"]:hover {
  @apply !border-transparent !bg-primary-hover-light !text-white dark:!bg-primary-hover-dark dark:!text-white;
}

.react-icon {
  @apply !icon-md;
}

span[class*="Tooltip-module_tooltip"],
div[class*="Errors-module_errors"] p {
  @apply !gap-1.5;
}

span[class*="Tooltip-module_message"] {
  @apply !w-max !hyphens-auto !break-words !rounded !border-none !bg-gray-100 !px-1.5 !py-1 !text-xs !text-lt dark:!bg-zinc-800;
}

input[class*="Input-module_select"] {
  @apply !text-gray-700 !focus-ring-none dark:!text-zinc-300;
}

div[class*="Input-module_option"] {
  @apply !text-sm !text-gray-700 dark:!text-zinc-300;
}

div[class*="MapColumns-module_samples"] {
  @apply !text-gray-700 dark:!text-zinc-300;
}

label[class*="Checkbox-module_container"] {
  @apply !gap-0 !border-none !bg-transparent;
}

label[class*="Checkbox-module_container"] input[type="checkbox"]:checked,
label[class*="Checkbox-module_container"] input[type="checkbox"]:disabled:checked {
  @apply !border !border-primary-base !bg-primary-base dark:!border-primary-base dark:!bg-primary-base;
}

label[class*="Checkbox-module_container"] input[type="checkbox"] {
  @apply !border !border-gray-300 !bg-white !focus-ring-none dark:!border-zinc-600 dark:!bg-zinc-850;
}

label[class*="Checkbox-module_container"] input[type="checkbox"]:checked::before {
  @apply !shadow-none;
}

div[class*="Default-module_td"] {
  @apply !text-sm !font-medium;
}

button[class*="Input-module_placeholder"],
input[class*="Input-module_select"]::placeholder {
  @apply !text-gray-400 dark:!text-zinc-500;
}

div[class*="Input-module_inputWrapper"] {
  @apply !bg-transparent;
}

div[class*="csv-importer"] {
  @apply !rounded !text-sm;
}

div[class*="Stepper-module_stepper"] {
  @apply !text-base;
}

.csv-importer-0 {
  @apply !text-base !text-gray-500 dark:!text-zinc-400;
}

button[class*="Input-module_option"]:focus {
  @apply !hidden;
}

div[class*="Input-module_inner"] {
  @apply !rounded !border-none !bg-gray-100 dark:!bg-zinc-800;
}

button[class*="Input-module_option"]:hover {
  @apply !bg-gray-300 dark:!bg-zinc-600;
}

button[class*="Input-module_selected"],
button[class*="Input-module_option"]::after {
  @apply !bg-gray-200 dark:!bg-zinc-700;
}

div[class*="Uploader-module_tableContainer"] {
  @apply !overflow-hidden !rounded;
}

div[class*="Default-module_tbody"] {
  @apply !max-h-[500px] !overflow-y-scroll;
}

span[class*="Input-module_requiredMark"] {
  @apply !text-gray-500 dark:!text-zinc-400;
}

div[class*="Input-module_inputWrapper"]:has(input[class*="Input-module_open"]) {
  @apply !outline-none;
}

div[class*="Complete-module_actions"] {
  @apply !mt-6;
}

div[class*="Uploader-module_tableContainer"] div[class*="Default-module_element"]:nth-child(2),
div[class*="Default-module_element"]:nth-child(4) {
  @apply !justify-center;
}

div[class*="Uploader-module_content"] div[class*="csv-importer"] {
  @apply !h-[543px];
}

div[class*="Uploader-module_content"] div[class*="csv-importer"][role="presentation"] {
  @apply !h-[513px];
}

div[class*="MapColumns-module_actions"] button[class*="csv-importer"][type="button"] {
  @apply !pointer-events-none !opacity-0;
}
</style>
