<script setup lang="ts">
import moment from "moment";

import { ReminderIcon } from "~/icons";
import { getRelativeTimeForReminder } from "~/utils/time";

defineProps<{
  remindAt: string | null;
  alwaysShow?: boolean;
  onClickOpen?: (event: KeyboardEvent | MouseEvent) => void;
  onClickFinishedIndicator?: (event: KeyboardEvent | MouseEvent) => void;
}>();
</script>

<template>
  <div>
    <!-- Reminder expired indicator -->
    <button
      v-if="remindAt && moment(remindAt).isSameOrBefore(moment())"
      type="button"
      class="flex items-center justify-center rounded-full focus-ring-none icon-md"
      tabindex="-1"
      @click="onClickFinishedIndicator"
      @keydown.enter="onClickFinishedIndicator">
      <div class="size-2 rounded-full bg-primary-base" />
      <span class="sr-only">Reminder expired indicator</span>
    </button>
    <!-- Reminder countdown -->
    <button
      v-if="remindAt && moment(remindAt).isAfter(moment())"
      type="button"
      class="flex h-5 items-center justify-center gap-1 rounded border pl-0.5 pr-1 text-vlt border-oncolor focus-ring-none hover:bg-opposite/10"
      tabindex="-1"
      @click="onClickOpen"
      @keydown.enter="onClickOpen">
      <span class="sr-only">Reminder countdown</span>
      <ReminderIcon class="icon-xs" />
      <span class="text-xs text-md">
        {{ getRelativeTimeForReminder(moment(remindAt)).value }}
      </span>
    </button>
    <!-- Reminder button -->
    <button
      v-if="alwaysShow && !remindAt"
      type="button"
      class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md"
      tabindex="-1"
      @click.stop="onClickOpen"
      @keydown.enter="onClickOpen">
      <span class="sr-only">Set reminder</span>
      <ReminderIcon class="icon-sm" />
    </button>
  </div>
</template>
