<script setup lang="ts">
import { computed, nextTick, ref, watch, watchEffect } from "vue";

import Input from "~/components/dumb/Input.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { IconKind, PageKind, SpaceKind } from "~/shared/enums";
import type { Page, Space } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getEmojiRecommendation } from "~/utils/recommendation";
import { ThrottleManager } from "~/utils/throttleManager";

const DISABLED_STYLE = "pointer-events-none select-none opacity-50";

const props = defineProps<{
  space: Space;
}>();

const dataStore = useDataStore();

const formatAbbreviation = (title: string) => title.replace(/\s+/g, "").substring(0, 4).toUpperCase() || "MAR";

const titleInput = ref<InstanceType<typeof Input> | null>(null);

const title = ref(props.space?.title ?? "");
const abbreviation = ref(props.space?.abrev ?? "");
const description = ref(props.space?.description ?? "");

const isGeneralOrPersonalSpace = computed(
  () => props.space.kind === SpaceKind.WORKSPACE || props.space.kind === SpaceKind.PERSONAL
);

const updateField = (spaceDuid: string, spacePartial: Partial<Space>) => {
  dataStore.updateSpace({ duid: spaceDuid, ...spacePartial });
};

const saveTitleManager = new ThrottleManager((spaceDuid: string) => {
  updateField(spaceDuid, { title: title.value.trim() });
}, THROTTLE_MS);

const updateTitle = (newValue: string) => {
  if (newValue === title.value) {
    return;
  }
  title.value = newValue.trim() || props.space?.title || "";
  saveTitleManager.run(props.space.duid);
};

const saveAbrevManager = new ThrottleManager((spaceDuid: string) => {
  updateField(spaceDuid, { abrev: formatAbbreviation(abbreviation.value) });
}, THROTTLE_MS);

const updateAbbreviation = (newValue: string) => {
  const newAbbrev = formatAbbreviation(newValue);
  if (newAbbrev === abbreviation.value) {
    return;
  }
  abbreviation.value = newAbbrev;
  saveAbrevManager.run(props.space.duid);
};

const saveDescriptionManager = new ThrottleManager((spaceDuid: string) => {
  updateField(spaceDuid, { description: description.value.trim() });
}, THROTTLE_MS);

const updateDescription = (newValue: string) => {
  if (newValue === description.value) {
    return;
  }
  description.value = newValue;
  saveDescriptionManager.run(props.space.duid);
};

const getEmojiRecommendationMaybe = async (newValue: string) => {
  if (!props.space || props.space.iconKind !== IconKind.NONE) {
    return;
  }

  saveTitleManager.cancel();
  const newTitle = newValue.trim();
  if (newTitle.length === 0) {
    return;
  }

  const spaceDuid = props.space.duid;
  const emojiRecUpdate = await getEmojiRecommendation(spaceDuid, newTitle);
  dataStore.updatePage({ duid: spaceDuid, title: newTitle, ...emojiRecUpdate }, PageKind.SPACE);
};

const focusTitle = () => {
  nextTick(() => {
    if (titleInput.value && props.space.title === "" && !isGeneralOrPersonalSpace.value) {
      titleInput.value.focus();
    }
  });
};

watchEffect(() => {
  if (props.space.duid) {
    focusTitle();
  }
});

watchEffect(() => {
  if (props.space.title) {
    updateAbbreviation(props.space.title);
  }
});

watch(
  () => props.space,
  (newSpace, oldSpace) => {
    if (newSpace.duid === oldSpace.duid) {
      return;
    }
    saveTitleManager.finish();
    saveAbrevManager.finish();
    saveDescriptionManager.finish();
    title.value = newSpace.title;
    abbreviation.value = newSpace.abrev;
    description.value = newSpace.description;
  }
);
</script>

<template>
  <div class="flex flex-col gap-2" :class="isGeneralOrPersonalSpace && DISABLED_STYLE" @click.stop @keydown.stop>
    <div class="font-medium text-md">Basics</div>
    <div class="flex flex-wrap items-center gap-2 lg:flex-row">
      <PageIconPicker :page="space">
        <Tooltip text="Change icon">
          <span class="flex size-9 items-center justify-center rounded border bg-std border-hvy hover:bg-opposite/10">
            <PageIcon :page="space as Page" />
          </span>
        </Tooltip>
      </PageIconPicker>
      <Input
        ref="titleInput"
        :init-value="space.title"
        required
        placeholder="e.g. Marketing"
        label="Title"
        data-testid="space-title"
        class="flex-1"
        @change="updateTitle"
        @finalize="getEmojiRecommendationMaybe" />
      <Input
        :init-value="space.abrev"
        :placeholder="formatAbbreviation(space.title)"
        label="Abbreviation"
        data-testid="space-abrev"
        class="mt-4 w-full lg:mt-0 lg:w-24"
        @change="updateAbbreviation" />
    </div>
  </div>
  <div :class="isGeneralOrPersonalSpace && DISABLED_STYLE" class="mt-4">
    <Input
      :init-value="space.description"
      placeholder="Add a description"
      label="Description"
      hide-label-nonempty
      @change="updateDescription" />
  </div>
</template>
