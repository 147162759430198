<script setup lang="ts">
/* This file can't contain any editor/store logic */
import { XIcon } from "~/icons";
</script>

<template>
  <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/smart-match:flex" />
  <div class="absolute -right-1.5 top-0 hidden h-3 w-1.5 group-hover/smart-match:flex" />
  <button
    type="button"
    aria-label="Decline match"
    class="dart-smart-match-close absolute -right-1.5 -top-1.5 z-10 hidden cursor-pointer items-center justify-center rounded-full border bg-lt border-md icon-xs group-hover/smart-match:flex">
    <XIcon class="size-full text-lt focus:outline-none" />
  </button>
</template>
