<script setup lang="ts">
import Button from "~/components/dumb/Button.vue";
import { SlashIcon } from "~/icons";
import { ButtonStyle, InboxFilter } from "~/shared/enums";
import type InboxView from "~/views/InboxView.vue";

defineProps<{
  inbox: InstanceType<typeof InboxView>;
}>();
</script>

<template>
  <div class="-my-3 flex items-center">
    <SlashIcon class="-mr-1 text-lt icon-sm" />
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      text="Unread"
      :text-style="inbox.filter === InboxFilter.UNREAD ? undefined : 'text-vlt'"
      borderless
      class="mt-[9px] rounded-b-none border-b-2 !px-2 pb-[13px]"
      :class="inbox.filter === InboxFilter.UNREAD && 'border-b-primary-base'"
      @click="inbox.setFilter(InboxFilter.UNREAD)" />
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      text="All"
      borderless
      :text-style="inbox.filter === InboxFilter.NONE ? undefined : 'text-vlt'"
      class="mt-[9px] rounded-b-none border-b-2 !px-2 pb-[13px]"
      :class="inbox.filter === InboxFilter.NONE && 'border-b-primary-base'"
      @click="inbox.setFilter(InboxFilter.NONE)" />
  </div>
</template>
