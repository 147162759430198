<script setup lang="ts">
defineProps<{
  msg: string | null | undefined;
  show?: boolean;
}>();
</script>

<template>
  <div class="mx-1 flex min-h-5 py-0.5">
    <span v-if="show && msg" class="select-none text-xs/[16px] text-danger-base">
      {{ msg }}
    </span>
  </div>
</template>
