<script setup lang="ts">
import { type Component, computed, watch } from "vue";
import { useRouter } from "vue-router";

import Modal from "~/components/dumb/Modal.vue";
import SettingsLeftbar from "~/components/SettingsLeftbar.vue";
import { FORM_TELEPORT_KEY } from "~/components/text/const";
import { getQueryParam } from "~/router/common";
import { ModalWidth, UserRole } from "~/shared/enums";
import { useAppStore, useUserStore } from "~/stores";
import AutomationsView from "~/views/settings/AutomationsView.vue";
import BillingView from "~/views/settings/BillingView.vue";
import ChatgptView from "~/views/settings/ChatgptView.vue";
import CliView from "~/views/settings/CliView.vue";
import DeleteView from "~/views/settings/DeleteView.vue";
import DiscordView from "~/views/settings/DiscordView.vue";
import ExportView from "~/views/settings/ExportView.vue";
import FormsView from "~/views/settings/FormsView";
import GithubView from "~/views/settings/GithubView.vue";
import IdentityView from "~/views/settings/IdentityView.vue";
import ImportView from "~/views/settings/ImportView.vue";
import MailView from "~/views/settings/MailView.vue";
import NotificationsView from "~/views/settings/NotificationsView.vue";
import NotionView from "~/views/settings/NotionView.vue";
import PipedreamView from "~/views/settings/PipedreamView.vue";
import ProfileView from "~/views/settings/ProfileView.vue";
import PropertiesView from "~/views/settings/PropertiesView";
import SlackView from "~/views/settings/SlackView.vue";
import SpacesView from "~/views/settings/SpacesView/SpacesView.vue";
import TaskKindsView from "~/views/settings/TaskKindsView";
import TeammatesView from "~/views/settings/TeammatesView.vue";
import WebhooksView from "~/views/settings/WebhooksView.vue";
import WorkspaceView from "~/views/settings/WorkspaceView.vue";
import ZapierView from "~/views/settings/ZapierView.vue";
import ZohoFlowView from "~/views/settings/ZohoFlowView.vue";

const ROUTE_TO_COMPONENT = new Map<string, [UserRole | null, UserRole[], Component]>([
  ["account", [null, [], ProfileView]],
  ["automations", [UserRole.ADMIN, [], AutomationsView]],
  ["billing", [UserRole.ADMIN, [UserRole.FINANCIAL_ADMIN], BillingView]],
  ["chatgpt", [UserRole.GUEST, [UserRole.TECHNICAL_ADMIN], ChatgptView]],
  ["cli", [UserRole.GUEST, [UserRole.TECHNICAL_ADMIN], CliView]],
  ["delete", [null, [], DeleteView]],
  ["discord", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], DiscordView]],
  ["export", [UserRole.GUEST, [], ExportView]],
  ["forms", [UserRole.MEMBER, [], FormsView]],
  ["github", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], GithubView]],
  ["identity", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], IdentityView]],
  ["import", [UserRole.MEMBER, [], ImportView]],
  ["mail", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], MailView]],
  ["notifications", [null, [], NotificationsView]],
  ["notion", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], NotionView]],
  ["pipedream", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], PipedreamView]],
  ["properties", [UserRole.ADMIN, [], PropertiesView]],
  ["slack", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], SlackView]],
  ["spaces", [UserRole.GUEST, [], SpacesView]],
  ["teammates", [UserRole.MEMBER, [], TeammatesView]],
  ["types", [UserRole.ADMIN, [], TaskKindsView]],
  ["webhooks", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], WebhooksView]],
  ["workspace", [UserRole.ADMIN, [], WorkspaceView]],
  ["zapier", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], ZapierView]],
  ["zoho-flow", [UserRole.ADMIN, [UserRole.TECHNICAL_ADMIN], ZohoFlowView]],
]);

const router = useRouter();
const appStore = useAppStore();
const userStore = useUserStore();

const userIsAdmin = computed(() => userStore.isRoleGreaterOrEqual(UserRole.ADMIN));

watch(
  () => router.currentRoute.value.query.settings,
  () => {
    const settings = getQueryParam("settings");
    if (!settings) {
      appStore.setSettingsModalOpen(false);
      return;
    }
    const config = ROUTE_TO_COMPONENT.get(settings);
    const minRole = config?.[0];
    const overrideRoles = config?.[1];
    if (
      minRole === undefined ||
      (minRole !== null && !userStore.isRoleGreaterOrEqual(minRole) && !overrideRoles?.includes(userStore.role))
    ) {
      setTimeout(() => {
        const query = { ...router.currentRoute.value.query };
        query.settings = userIsAdmin.value ? "workspace" : "account";
        router.replace({ query });
      }, 100);
      appStore.setSettingsModalOpen(false);
      return;
    }
    appStore.setSettingsModalOpen(true);
  },
  { immediate: true }
);

const currentComponent = computed(() => {
  const settings = getQueryParam("settings");
  return ROUTE_TO_COMPONENT.get(settings ?? "")?.[2];
});
</script>

<template>
  <Modal
    id="dart-form-wrapper"
    :entity="appStore.settingsModalOpen"
    title="Settings"
    :width="ModalWidth.XXL"
    hide-title
    overflow-clip
    custom-styles="h-[calc(100vh-44px)] sm:h-[45rem] !p-0 overflow-hidden"
    @close="appStore.setSettingsModalOpen(false)">
    <template #default="{ entity: settingsModalOpen }">
      <template v-if="settingsModalOpen">
        <div :data-toolbar="FORM_TELEPORT_KEY" class="flex size-full flex-col text-left sm:flex-row">
          <SettingsLeftbar />

          <!-- Main content -->
          <main class="-mt-14 ml-0 max-h-full w-full pl-0 pt-14 sm:-ml-56 sm:mt-0 sm:pl-56 sm:pt-0">
            <component :is="currentComponent" class="mt-10 pb-72" />
          </main>
        </div>
      </template>
    </template>
  </Modal>
</template>
