<script setup lang="ts">
import { DartIcon } from "~/icons";

defineProps<{
  isContrast?: boolean;
}>();
</script>

<template>
  <!-- TODO make whole thing link and switch separate logo and text to be one image -->
  <div
    class="flex select-none items-center gap-2"
    :class="isContrast ? 'text-vlt' : 'text-gray-300 dark:text-zinc-600'">
    <span class="whitespace-nowrap text-sm">Powered by</span>
    <a
      class="group/brand-link flex cursor-pointer items-center gap-1"
      href="https://itsdart.com"
      target="_blank"
      rel="noopener noreferrer">
      <DartIcon
        class="saturate-0 icon-lg group-hover/brand-link:opacity-100 group-hover/brand-link:saturate-100"
        :class="isContrast ? 'opacity-70' : 'opacity-60'" />
      <span class="text-base group-hover/brand-link:text-md">Dart</span>
    </a>
  </div>
</template>
