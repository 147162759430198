<script setup lang="ts">
import { computed, ref } from "vue";

import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import TextInput from "~/components/dumb/TextInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PagePropertiesEditor from "~/components/PagePropertiesEditor.vue";
import type { Page } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";

const props = defineProps<{
  page: Page;
  editable: boolean;
  showDescriptionOption?: boolean;
  label: string;
}>();

const emit = defineEmits<{
  startEditingDescription: [];
}>();

const dataStore = useDataStore();

const inputTitle = ref<InstanceType<typeof TextInput> | null>(null);
const isEditingTitle = ref(false);

const title = computed(() => getPageDisplayName(props.page, dataStore.getSpaceByDuid));

const startEditingTitle = () => {
  if (!props.editable) {
    return;
  }

  isEditingTitle.value = true;
  inputTitle.value?.startEditing();
};

const stopIfEditingTitle = (e: Event) => {
  if (isEditingTitle.value) {
    e.stopPropagation();
  }
};

const updatePageTitle = (value: string) => {
  if (!props.page) {
    return;
  }
  isEditingTitle.value = false;

  const trimmedValue = value.trim();
  if (trimmedValue === "") {
    return;
  }

  dataStore.updatePage({ duid: props.page.duid, title: trimmedValue }, props.page.pageKind);
};

defineExpose({
  isEditingTitle,
});
</script>

<template>
  <div class="ml-1 mr-0.5 flex items-center rounded pl-0.5" :class="!isEditingTitle && 'hover:bg-lt'">
    <PageIconPicker v-if="editable" :page="props.page">
      <Tooltip text="Change icon">
        <span class="flex items-center justify-center rounded p-0.5 hover:bg-md">
          <PageIcon :page="props.page" />
        </span>
      </Tooltip>
    </PageIconPicker>
    <PageIcon v-else :page="props.page" class="mx-0.5" />
    <PagePropertiesEditor
      :page="page"
      :show-description-option="showDescriptionOption"
      :disabled="isEditingTitle"
      class="truncate"
      @start-editing-title="startEditingTitle"
      @start-editing-description="emit('startEditingDescription')">
      <div
        class="my-0.5 ml-1 mr-0.5 flex w-full items-center rounded text-sm text-md"
        @click="stopIfEditingTitle"
        @keydown.enter="stopIfEditingTitle">
        <TextInput
          ref="inputTitle"
          :text="title"
          :label="label"
          :editable="editable"
          data-testid="topbar-page-title"
          @save="updatePageTitle" />
      </div>
    </PagePropertiesEditor>
  </div>
</template>
