<script setup lang="ts">
defineProps({
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  styles: {
    type: Object,
    default() {
      return {
        marginTop: "",
        flex: "grow",
        alignItems: "items-center",
        maxWidth: "",
      };
    },
  },
});
</script>

<template>
  <div :class="[styles.marginTop, 'mx-10 my-12']">
    <div :class="[styles.alignItems, 'flex justify-between gap-4']">
      <span :class="[styles.flex, styles.maxWidth, 'flex flex-col']">
        <span class="select-none font-semibold text-md">{{ title }}</span>
        <span class="select-none text-xs text-lt">{{ subtitle }}</span>
      </span>
      <slot />
    </div>
  </div>
</template>
