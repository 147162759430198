<script setup lang="ts">
defineProps<{
  title: string;
}>();
</script>

<template>
  <span class="truncate text-sm font-semibold text-hvy" :title="title">
    {{ title }}
  </span>
</template>
