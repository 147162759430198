<script setup lang="ts">
import { useKeyModifier } from "@vueuse/core";
import type { ICellRendererParams } from "ag-grid-community";
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";

import actions from "~/actions";
import { UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import Checkbox from "~/components/dumb/Checkbox.vue";
import TitleEditor from "~/components/dumb/TitleEditor.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { ChevronDownIcon } from "~/icons";
import { CommandId, EditorMode, SubtaskDisplayMode, ViewKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, usePageStore } from "~/stores";

const props = defineProps<{
  params: ICellRendererParams<Task> & {
    editorMode: EditorMode;
  };
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const shiftState = useKeyModifier("Shift");

const isPublicViewOrTrash = computed(() => pageStore.isPublicView || appStore.currentPage?.kind === ViewKind.TRASH);

const isListMiniBaseMode = computed(() => props.params.editorMode === EditorMode.LIST_MINI);
const isListMiniTcmMode = computed(() => props.params.editorMode === EditorMode.LIST_MINI_TCM);

const editor = ref<InstanceType<typeof TitleEditor> | null>(null);

let initialized = false;
const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);
const isParent = ref(false);
const level = ref(0);
const isExpanded = ref(false);

const isHovered = computed(() => !!props.params.data && props.params.data?.duid === appStore.hoverRow?.data?.duid);
const isSelected = computed(() => !!props.params.data && appStore.selectedTaskDuids.has(props.params.data?.duid));
const showCheckbox = computed(() => isHovered.value || isSelected.value);
const chevronHardHidden = computed(() => isPublicViewOrTrash.value && !isParent.value);
const showChevron = computed(() => isParent.value || isHovered.value || isSelected.value);

const updateHierarchy = async () => {
  await nextTick();
  const newIsParent = props.params.node.allChildrenCount !== null;
  if (initialized && newIsParent && !isParent.value) {
    props.params.node.setExpanded(props.params.data?.expanded ?? true);
  } else {
    isExpanded.value = newIsParent ? props.params.node.expanded : false;
  }
  isParent.value = newIsParent;
  level.value = props.params.node.uiLevel - (appStore.groupBy === UNGROUPED_PSEUDO_GROUP_BY ? 0 : 1);
  initialized = true;
};
updateHierarchy();

props.params.node.addEventListener("allChildrenCountChanged", updateHierarchy);
props.params.node.addEventListener("expandedChanged", updateHierarchy);
onUnmounted(() => {
  props.params.node.removeEventListener("allChildrenCountChanged", updateHierarchy);
  props.params.node.removeEventListener("expandedChanged", updateHierarchy);
});

const leftPaddingPx = computed(() => {
  if (isListMiniBaseMode.value || isListMiniTcmMode.value) {
    return 0;
  }
  if (!notFlat.value) {
    return 4;
  }
  return level.value * 20;
});

const toggleSubtasks = () => {
  if (isParent.value) {
    props.params.node.setExpanded(!isExpanded.value);
    return;
  }

  if (isPublicViewOrTrash.value) {
    return;
  }

  isExpanded.value = true;
  props.params.node.setExpanded(true);
  actions.visualization.createSubtask(props.params.data);
};

const toggleSelected = (newValue: boolean) => {
  actions.visualization.selectWithClick(props.params.node, newValue, !!shiftState.value);
};

onMounted(() => {
  if (props.params.editorMode !== EditorMode.LIST) {
    return;
  }
  pageStore.incrementCellRenderCount();
});

defineExpose({
  focus: () => editor.value?.focus(),
});
</script>

<template>
  <TaskClickWrapper v-if="params.data" :task="params.data" :editor-mode="params.editorMode">
    <template #default="args">
      <div
        v-bind="args"
        class="flex h-full items-center gap-1 pr-2"
        :class="{
          'pl-[17px]': isListMiniBaseMode,
          'pl-[22px]': isListMiniTcmMode,
          'pl-2': !(isListMiniBaseMode || isListMiniTcmMode),
        }">
        <Tooltip
          v-if="!pageStore.isPublicView && !(isListMiniBaseMode || isListMiniTcmMode)"
          :class="!showCheckbox && 'opacity-0'"
          :command-id="CommandId.SELECT_TASK">
          <Checkbox
            :value="isSelected"
            label="Toggle task selection"
            class="!pointer-events-auto"
            @change="toggleSelected"
            @click.stop />
        </Tooltip>
        <div class="flex h-full min-w-0 grow items-center gap-1" :style="{ 'padding-left': `${leftPaddingPx}px` }">
          <Tooltip
            v-if="!(isListMiniBaseMode || isListMiniTcmMode) && notFlat"
            :disabled="chevronHardHidden"
            :command-id="
              isParent ? (isExpanded ? CommandId.HIDE_SUBTASKS : CommandId.SHOW_SUBTASKS) : CommandId.CREATE_SUBTASKS
            "
            class="dart-subtask-expand rounded opacity-0"
            :class="{
              'cursor-pointer opacity-100 hover:bg-opposite/10': showChevron && !chevronHardHidden,
            }">
            <ChevronDownIcon
              class="shrink-0 transition-transform text-vlt icon-sm focus:outline-none print:hidden"
              :class="[!isExpanded && '-rotate-90', isParent ? 'text-lt' : 'text-vlt']"
              @click="toggleSubtasks"
              @keydown.enter="toggleSubtasks" />
          </Tooltip>
          <TitleEditor
            ref="editor"
            :task="params.data"
            :value="params.data.title"
            :editor-mode="params.editorMode"
            :has-shown-parent="level > 0"
            :subtasks-expanded="isExpanded"
            data-testid="title-cell-editor"
            @toggle-subtasks="toggleSubtasks" />
        </div>
      </div>
    </template>
  </TaskClickWrapper>
</template>
