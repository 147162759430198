<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import { backendOld } from "~/api";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import FormError from "~/components/dumb/FormError.vue";
import Input from "~/components/dumb/Input.vue";
import { GoogleIcon } from "~/icons";
import { finishLogin, getQueryParam } from "~/router/common";
import { AnimationDuration, ButtonSize, ButtonStyle, EventKind, GoogleLoginAction } from "~/shared/enums";
import { useEnvironmentStore, usePageStore } from "~/stores";
import { getGoogleUrl, run, validateEmail, validateName, validatePassword } from "~/utils/common";

import AuthBaseView from "./AuthBaseView.vue";
import OpenEmail from "./OpenEmail.vue";

enum Step {
  INITIAL,
  EMAIL,
  FULL,
  WAITING_ON_VERIFICATION,
}

const OAUTH_HOSTS_TO_TOKEN_SLUGS = new Map([["chat.openai.com", "ChatGPT"]]);

const router = useRouter();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();

// Sign-up with token
const tokenId = ref(getQueryParam("t") ?? "");

const setTokenIdFromNext = () => {
  const next = getQueryParam("next");
  if (!next) {
    return;
  }
  const nextDecoded = new URLSearchParams(decodeURIComponent(decodeURIComponent(next)));
  const oauthRedirect = nextDecoded.get("redirect_uri");
  if (!oauthRedirect) {
    return;
  }
  const sourceHost = new URL(oauthRedirect).host;
  const tokenSlug = OAUTH_HOSTS_TO_TOKEN_SLUGS.get(sourceHost);
  if (!tokenSlug) {
    return;
  }
  tokenId.value = tokenSlug;
};

if (!tokenId.value) {
  setTokenIdFromNext();
}
const query = { ...router.currentRoute.value.query };
delete query.t;
router.replace({ query });

if (tokenId.value) {
  run(async () => {
    const { data } = await backendOld.auth.checkReferralTokenAndMaybeCreateAndLogin(tokenId.value);
    if ("valid" in data) {
      pageStore.pageLoaded = true;
      if (!data.valid) {
        tokenId.value = "";
      }
    } else {
      finishLogin(data);
    }
  });
} else {
  pageStore.pageLoaded = true;
}

const emailInput = ref<InstanceType<typeof Input> | null>(null);
const nameInput = ref<InstanceType<typeof Input> | null>(null);
const passwordInput = ref<InstanceType<typeof Input> | null>(null);

const step = ref(Step.INITIAL);
const emailCached = ref<string | undefined>();
const errorMsg = ref<string | null>(null);

const submitting = ref(false);
const submitButtonEnabled = computed(
  () =>
    step.value === Step.FULL && emailInput.value?.isValid && nameInput.value?.isValid && passwordInput.value?.isValid
);

const signUp = async () => {
  if (!submitButtonEnabled.value || !emailInput.value || !nameInput.value || !passwordInput.value) {
    return;
  }

  submitting.value = true;

  const { data } = await backendOld.auth.signUp(
    emailInput.value.value,
    nameInput.value.value,
    passwordInput.value.value,
    tokenId.value,
    {
      next: getQueryParam("next"),
    }
  );
  submitting.value = false;

  if ("valid" in data) {
    if (data.valid) {
      emailCached.value = emailInput.value.value;
      step.value = Step.WAITING_ON_VERIFICATION;
    } else {
      errorMsg.value = data.errors?.[0] ?? "An unknown error occurred";
    }
  } else {
    finishLogin(data);
  }
};

const nextStep = async () => {
  if (step.value === Step.INITIAL) {
    step.value = Step.EMAIL;
    await nextTick();
    emailInput.value?.focus();
    return;
  }
  if (step.value === Step.EMAIL) {
    step.value = Step.FULL;
    await nextTick();
    nameInput.value?.focus();
    return;
  }
  if (submitButtonEnabled.value) {
    signUp();
  }
};

const googleUrl = computed(() =>
  getGoogleUrl(
    GoogleLoginAction.SIGN_UP,
    environmentStore.googleRedirectUri,
    pageStore.isDesktopApp,
    pageStore.isMobileApp,
    undefined,
    tokenId.value || undefined,
    getQueryParam("next")
  )
);

const displayContinueInBrowser = ref(false);

const toggleContinueInBrowser = () => {
  if (!pageStore.isDesktopApp && !pageStore.isMobileApp) {
    return;
  }

  displayContinueInBrowser.value = !displayContinueInBrowser.value;
};

const goBackOneStep = () => {
  if (step.value === Step.FULL) {
    step.value = Step.EMAIL;
    return;
  }
  step.value = Step.INITIAL;
};

backendOld.createEvent(EventKind.LOAD_SIGNUP);

const showNoAccountMessage = ref(false);

const redirectFromLogin = async () => {
  const emailParam = getQueryParam("email");
  if (step.value !== Step.INITIAL || !emailParam) {
    return;
  }

  emailCached.value = emailParam;
  showNoAccountMessage.value = true;
  validateEmail(emailInput.value?.value ?? "");

  delete query.email;
  router.replace({ query });

  step.value = Step.FULL;
  await nextTick();
  nameInput.value?.focus();
};

onMounted(() => {
  redirectFromLogin();
});
</script>

<template>
  <AuthBaseView>
    <template #heading>Welcome to Dart!</template>

    <template #default>
      <div
        v-if="step !== Step.WAITING_ON_VERIFICATION"
        class="-mt-6 mb-2 select-none self-center hyphens-auto break-words text-center text-vlt">
        Create your account
      </div>
      <Animated v-if="!displayContinueInBrowser" :duration="AnimationDuration.LONG" class="flex w-full flex-col gap-1">
        <template v-if="step !== Step.WAITING_ON_VERIFICATION">
          <template v-if="step === Step.INITIAL">
            <a
              v-if="!environmentStore.isPreview"
              :href="googleUrl"
              :target="pageStore.isDesktopApp ? '_blank' : undefined"
              :rel="pageStore.isDesktopApp ? 'noopener noreferrer' : undefined">
              <span class="sr-only">Sign up with Google</span>
              <Button
                :btn-style="ButtonStyle.PRIMARY"
                text="Sign up with Google"
                :icon="GoogleIcon"
                block
                class="mt-2" />
            </a>

            <div class="flex w-full items-center gap-1 py-4">
              <div class="h-px grow bg-md" />
              <span class="select-none text-xs uppercase text-vlt">or</span>
              <div class="h-px grow bg-md" />
            </div>

            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.LARGE"
              text="Sign up with email"
              block
              data-testid="sign-up-with-email"
              class="mb-6"
              @click="nextStep" />
          </template>

          <Animated
            v-else
            element="form"
            :duration="AnimationDuration.LONG"
            class="flex flex-col gap-4"
            @submit.prevent>
            <div class="relative">
              <Input
                ref="emailInput"
                :init-value="emailCached"
                input-type="email"
                required
                placeholder="Enter your work email"
                label="Email"
                data-testid="email"
                :disabled="submitting"
                :validate="validateEmail"
                @enter="nextStep" />
              <span
                v-if="showNoAccountMessage && emailInput?.isValid"
                class="absolute bottom-0 select-none text-xs text-primary-base">
                No account found for this email, sign up
              </span>
            </div>
            <template v-if="step === Step.FULL">
              <Input
                ref="nameInput"
                required
                placeholder="Enter your name"
                label="Name"
                data-testid="name"
                :disabled="submitting"
                :validate="validateName"
                @change="errorMsg = null"
                @enter="nextStep" />
              <Input
                ref="passwordInput"
                input-type="password"
                required
                placeholder="Enter a password"
                label="Password"
                data-testid="password"
                :disabled="submitting"
                :validate="validatePassword"
                @change="errorMsg = null"
                @enter="nextStep" />
            </template>
            <div class="-mb-5">
              <Button
                :btn-style="ButtonStyle.PRIMARY"
                :text="step === Step.EMAIL ? 'Continue' : 'Sign up'"
                :disabled="step === Step.EMAIL ? emailInput?.isValid !== true : !submitButtonEnabled"
                :working="submitting"
                data-testid="signup-action-button"
                block
                class="mt-3"
                @click="nextStep" />
              <FormError :show="!!errorMsg" :msg="errorMsg" />
            </div>
          </Animated>
        </template>

        <div v-else class="flex w-full flex-col items-center justify-center gap-10 text-base font-normal text-md">
          <div class="flex select-none flex-col hyphens-auto break-words text-center text-lt">
            Check {{ emailCached ?? "your email" }} and click the link
          </div>
          <OpenEmail :email="emailCached" />
        </div>
      </Animated>
      <template v-else>
        <p class="text-center text-2xl text-md">Finish in your browser</p>
      </template>
    </template>

    <template #footer>
      <button
        v-if="displayContinueInBrowser"
        type="button"
        class="select-none"
        @click="toggleContinueInBrowser"
        @keydown.enter="toggleContinueInBrowser">
        ← Back
      </button>
      <button
        v-else-if="step === Step.EMAIL || step === Step.FULL"
        type="button"
        class="select-none"
        @click="goBackOneStep"
        @keydown.enter="goBackOneStep">
        ← Back
      </button>
      <template v-else>
        <span class="select-none">Already have a Dart account?</span>
        <span class="text-primary-base hover:text-primary-hover-light dark:hover:text-primary-hover-dark">
          <RouterLink :to="{ name: 'login' }" class="select-none font-semibold">Log in</RouterLink>
        </span>
      </template>
    </template>
  </AuthBaseView>
</template>
